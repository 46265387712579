import request from '@/utils/request'


// 查询分佣列表
export function listDistribute(query) {
  return request({
    url: '/user/user-distribute/list',
    method: 'get',
    params: query
  })
}

// 查询分佣分页
export function pageDistribute(query) {
  return request({
    url: '/user/user-distribute/page',
    method: 'get',
    params: query
  })
}

// 查询分佣详细
export function getDistribute(data) {
  return request({
    url: '/user/user-distribute/detail',
    method: 'get',
    params: data
  })
}

// 新增分佣
export function addDistribute(data) {
  return request({
    url: '/user/user-distribute/add',
    method: 'post',
    data: data
  })
}

// 修改分佣
export function updateDistribute(data) {
  return request({
    url: '/user/user-distribute/edit',
    method: 'post',
    data: data
  })
}

// 删除分佣
export function delDistribute(data) {
  return request({
    url: '/user/user-distribute/delete',
    method: 'post',
    data: data
  })
}
